import Vue from 'vue';
import Router from 'vue-router';
import { store } from '@/store';

import { setPermissions, getFeatures } from '../utils';

Vue.use(Router);
const router = new Router({
  routes: [
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/pages/Dashboard'),
      meta: { permission: 'ReadFacilityAccessLogs' },
    },
    {
      path: '/facilities',
      name: 'facilities',
      component: () => import('@/pages/Facilities'),
      meta: { permission: 'ReadFacility' },
    },
    {
      path: '/savedsearches',
      name: 'savedsearches',
      component: () => import('@/pages/SavedSearches'),
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/pages/Users'),
      meta: { permission: 'ReadUserFacilities' },
    },
    {
      path: '/support',
      name: 'support',
      component: () => import('@/pages/Support'),
    },
    {
      path: '/devices',
      name: 'devices',
      component: () => import('@/pages/Devices'),
    },
    {
      path: '/facility-access-log/:facilityid',
      name: 'facilityinfo',
      component: () => import('@/pages/FacilityInfo'),
      meta: { permission: 'ReadFacilityAccessLogs' },
    },
    {
      path: '/muster',
      name: 'muster',
      component: () => import('@/pages/Muster'),
      meta: { permission: 'ReadFacilityAccessLogs' },
      beforeEnter: async (to, from, next) => {
        try {
          const features = await getFeatures();
          if (!features.onSite) {
            next({ name: 'UnauthorizedAccess' });
          } else {
            next();
          }
        } catch (err) {
          console.log(err);
          next({ name: 'UnauthorizedAccess' });
        }
      },
    },
    {
      path: '/cardholder-form/:cardholderId',
      name: 'cardholderform',
      component: () => import('@/pages/CardholderForm'),
    },
    {
      path: '/cardholder-groups',
      name: 'cardholdergroups',
      component: () => import('@/pages/CardholderGroups'),
      // meta: {permission: "ReadFacilityAccessLogs"}
    },
    {
      path: '/timetables',
      name: 'timetables',
      component: () => import('@/pages/Timetables'),
    },
    {
      path: '/access-control-lists',
      name: 'accesscontrollists',
      component: () => import('@/pages/AccessControlLists'),
    },
    {
      path: '/systempermissions',
      name: 'systempermissions',
      component: () => import('@/pages/SystemPermissions'),
    },
    {
      path: '/facilitypermissions',
      name: 'facilitypermissions',
      component: () => import('@/pages/FacilityPermissions'),
    },
    {
      path: '/',
      name: 'SignIn',
      component: () => import('@/pages/SignIn'),
      meta: {
        skipAuth: true,
      },
    },
    {
      path: '*',
      redirect: '/systemnotice',
      meta: {
        skipAuth: true,
      },
    },
    {
      path: '/forgot',
      name: 'forgot',
      component: () => import('@/pages/Forgot'),
      meta: {
        skipAuth: true,
      },
    },
    {
      path: '/confirmforgot/:token',
      name: 'confirmForgot',
      component: () => import('@/pages/ConfirmForgot'),
      meta: {
        skipAuth: true,
      },
    },
    {
      path: '/systemnotice',
      name: 'SystemNotice',
      component: () => import('@/pages/SystemNotice'),
      meta: {
        skipAuth: true,
      },
    },
    {
      path: '/unauthorized',
      name: 'UnauthorizedAccess',
      component: () => import('@/pages/UnauthorizedAccess'),
      meta: {
        skipAuth: true,
      },
    },
    {
      path: '/cardholders',
      name: 'cardholders',
      component: () => import('@/pages/Cardholders'),
    },
    {
      path: '/escorted-visitors/:visitorUUID',
      name: 'visitordetails',
      component: () => import('@/pages/VisitorDetails'),
      meta: { permission: 'ReadVisitorEscort' },
    },
    {
      path: '/escorted-visitors',
      name: 'escortedvisitors',
      component: () => import('@/pages/EscortedVisitors'),
      meta: { permission: 'ReadVisitorEscort' },
    },
    {
      path: '/tm/certifications',
      name: 'certifications',
      component: () => import('@/pages/Certifications'),
      meta: {
        skipAuth: true,
      },
    },
    {
      path: '/tm/expirations',
      name: 'expirations',
      component: () => import('@/pages/Expirations'),
    },
  ],
  mode: 'history',
});

/* eslint-disable-next-line consistent-return */
router.beforeEach(async (to, from, next) => {
  try {
    // moving between routes
    if (store.state.user) {
      // skip routes with true meta field skipAuth flag
      if (to.meta.skipAuth) {
        next();
      } else {
        // check if user has agreed to system notice
        if (store.getters.agreeToNotice !== true) {
          return next({ name: 'SystemNotice' });
        }

        const layoutPermissions = await setPermissions();

        // compare the permission requirement on the route to the permission
        // that the user has. If it doesn't exist, push to unauthorized
        // Note: This only applies to when routes are selected in the app
        // during a normal flow. This does not apply when manually entering URI
        // axios.js is handling 40x level http errors, and reroutes to unauthorized
        // when encountering an error.
        if (to.meta.permission && layoutPermissions[to.meta.permission] !== 1) {
          next({ name: 'UnauthorizedAccess' });
        } else {
          next();
        }
      }
      // potential page refresh
    } else if (localStorage.getItem('session')) {
      const session = JSON.parse(localStorage.getItem('session'));
      if (session) {
        // go to dashboard if logged in trying to access signin
        if (to.name === 'SignIn') {
          next({ name: 'dashboard' });
        }

        store.commit('setUser', session);
        store.commit('setLayout', 'layout-loggedIn');

        const layoutPermissions = await setPermissions();

        if (to.meta.permission && layoutPermissions[to.meta.permission] !== 1) {
          next({ name: 'UnauthorizedAccess' });
        } else {
          next();
        }
      }
      // no store.state.user, no localStorage session, not logged in
      // check if already on SignIn route, avoid infinite loop
    } else if (to.name === 'SignIn' || to.name === 'forgot' || to.name === 'confirmForgot') {
      next();
      // skip guard
    } else {
      next({ name: 'SignIn' });
    }
  } catch (error) {
    console.log(error);
    store.commit('setLayout', 'layout-default');
    next({ name: 'SignIn' });
  }
});

export default router;

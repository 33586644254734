import Vue from 'vue';
import Vuex from 'vuex';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import actions from '@/store/actions';
import { http } from '@/axios';
import { HTTPONLY_TOKEN } from '@/config';

Vue.use(Vuex);

const persistedState = JSON.parse(localStorage.getItem('vuexState')) || {};
/* eslint-disable import/prefer-default-export */
export const store = new Vuex.Store({
  modules: {},
  state: {
    activeFacility: null,
    agreeToNotice: !!JSON.parse(localStorage.getItem('agreeToNotice')),
    currentAccessLogRecord: persistedState.currentAccessLogRecord || {},
    currentAdminFacilityObj: null,
    currentFacilityObj: null,
    error: null,
    facilityTerminals: [],
    hourToggle: null,
    layout: 'layout-default',
    loading: false,
    networkError: false,
    searchTerm: '',
    user: null,
    userPermissions: [],
    headers: [
      {
        text: 'AccessDate',
        value: 'AccessDateTime',
        class: 'pa-1',
        active: 1,
      },
      {
        text: 'Name',
        value: 'Name',
        class: 'pa-1',
        active: 1,
      },
      {
        text: 'FASCN',
        value: 'FASCN',
        class: 'pa-1',
        active: 1,
      },
      {
        text: 'CDL',
        value: 'CDL',
        class: 'pa-1',
        active: 1,
      },
      {
        text: 'CIN',
        value: 'CIN',
        class: 'pa-1',
        active: 1,
      },
      {
        text: 'Result',
        value: 'ResultType',
        class: 'pa-1',
        active: 1,
      },
      {
        text: 'Expiration',
        value: 'ExpirationDate',
        class: 'pa-1',
        active: 1,
      },
      {
        text: 'CCL',
        value: 'CCLStatus',
        class: 'pa-1',
        active: 1,
      },
      {
        text: 'Chuid',
        value: 'ChuidStatus',
        class: 'pa-1',
        active: 1,
      },
      {
        text: 'FingerprintAuthStatus',
        value: 'FingerprintAuthStatus',
        class: 'pa-1',
        active: 0,
      },
      {
        text: 'PinAuthStatus',
        value: 'PinAuthStatus',
        class: 'pa-1',
        active: 0,
      },
      {
        text: 'Reason',
        value: 'Reason',
        class: 'pa-1',
        active: 0,
      },
      {
        text: 'Screening',
        value: 'ScreeningType',
        class: 'pa-1',
        active: 0,
      },
      {
        text: 'Terminal',
        value: 'Terminal',
        class: 'pa-1',
        active: 0,
      },
      {
        text: 'Access',
        value: 'AccessPoint',
        class: 'pa-1',
        active: 1,
      },
      {
        text: 'IMEI#',
        value: 'IMEINumber',
        class: 'pa-1',
        active: 1,
      },
      {
        text: 'Marsec ',
        value: 'MarsecLevel',
        class: 'pa-1',
        active: 1,
      },
      {
        text: 'Mode ',
        value: 'ModeLevel',
        class: 'pa-1',
        active: 1,
      },
      {
        text: 'Guard',
        value: 'Guard',
        class: 'pa-1',
        active: 1,
      },
      {
        text: 'Direction',
        value: 'Direction',
        class: 'pa-1',
        active: 1,
      },
    ],
  },
  mutations: {
    changeActiveFacility(state, activeFacility) {
      // eslint-disable-next-line no-param-reassign
      state.activeFacility = activeFacility;
    },
    setAgreeToNotice(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.agreeToNotice = payload;
      localStorage.setItem('agreeToNotice', state.agreeToNotice);
    },
    setCurrentAccessLogRecord(state, payload) {
      if (payload) {
        // eslint-disable-next-line no-param-reassign
        state.currentAccessLogRecord = payload;
        localStorage.setItem('vuexState', JSON.stringify(state));
      }
    },
    setError(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.error = payload;
    },
    changeTerminals(state, facilityTerminals) {
      // eslint-disable-next-line no-param-reassign
      state.facilityTerminals = facilityTerminals;
    },
    updateHourToggle(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.hourToggle = payload;
    },
    async setHourToggle(state, payload) {
      const params = { Id: payload.id, UseMilitaryTime: payload.hourToggle };
      try {
        await http.post('/api/hourtoggle', params);
        // eslint-disable-next-line no-param-reassign
        state.hourToggle = payload.hourToggle;
      } catch (error) {
        console.log(error);
      }
    },
    async getHourToggle(state, payload) {
      try {
        const response = await http.get(`/api/hourtoggle/${payload.id}`);
        const useMilitaryTime = response.data.UseMilitaryTime;
        const val = useMilitaryTime ? 1 : 0;
        // eslint-disable-next-line no-param-reassign
        state.hourToggle = val;
        return val;
      } catch (error) {
        // eslint-disable-next-line no-param-reassign
        state.hourToggle = 0;
        return 0;
      }
    },
    setLayout(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.layout = payload;
    },
    setLoading(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.loading = payload;
    },
    mutateNetworkError(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.networkError = payload;
    },
    setSearchTerm(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.searchTerm = payload;
    },
    setUser(state, payload) {
      if (payload && payload.token) {
        const decoded = jwtDecode(payload.token);

        if (decoded) {
          // eslint-disable-next-line no-param-reassign
          payload.id = decoded.id;
          // eslint-disable-next-line no-param-reassign
          payload.groups = decoded.groups || '';
          if (localStorage.session) {
            const d = JSON.parse(localStorage.session);
            // eslint-disable-next-line no-param-reassign
            payload.dashPicker1 = d.dashPicker1;
            // eslint-disable-next-line no-param-reassign
            payload.dashPicker2 = d.dashPicker2;
          } else {
            // eslint-disable-next-line no-param-reassign
            payload.dashPicker1 = moment().subtract(7, 'days').format('YYYY-MM-DD');
            // eslint-disable-next-line no-param-reassign
            payload.dashPicker2 = moment().format('YYYY-MM-DD');
          }
        }
      }
      // eslint-disable-next-line no-param-reassign
      state.user = payload;
      if (payload) {
        if (!HTTPONLY_TOKEN) {
          http.defaults.headers.common.Authorization = `Bearer ${payload.token}`;
        }
        const { token, ...noTokens } = payload;
        localStorage.setItem('session', JSON.stringify(HTTPONLY_TOKEN ? noTokens : payload));
      } else {
        localStorage.removeItem('session');
        if (!HTTPONLY_TOKEN) {
          delete http.defaults.headers.common.Authorization;
        }
      }
    },
    setUserPermissions(state, permissions) {
      // eslint-disable-next-line no-param-reassign
      state.userPermissions = permissions;
    },
    setLayoutPermissions(state, permissions) {
      // eslint-disable-next-line no-param-reassign
      state.layoutPermissions = permissions;
    },
    setPermissionsByFacility(state, permissions) {
      // eslint-disable-next-line no-param-reassign
      state.permissionsByFacility = permissions;
    },
    setHeaders(state, payload) {
      if (payload) {
        // eslint-disable-next-line no-param-reassign
        state.headers = payload;
        localStorage.setItem('headers', JSON.stringify(state.headers));
      }
    },
    setDefaultHeaders(state) {
      // eslint-disable-next-line no-param-reassign
      state.headers = [
        {
          text: 'AccessDate',
          value: 'AccessDateTime',
          class: 'pa-1',
          active: 1,
        },
        {
          text: 'Name',
          value: 'Name',
          class: 'pa-1',
          active: 1,
        },
        {
          text: 'FASCN',
          value: 'FASCN',
          class: 'pa-1',
          active: 1,
        },
        {
          text: 'CDL',
          value: 'CDL',
          class: 'pa-1',
          active: 1,
        },
        {
          text: 'CIN',
          value: 'CIN',
          class: 'pa-1',
          active: 1,
        },
        {
          text: 'Result',
          value: 'ResultType',
          class: 'pa-1',
          active: 1,
        },
        {
          text: 'Expiration',
          value: 'ExpirationDate',
          class: 'pa-1',
          active: 1,
        },
        {
          text: 'CCL',
          value: 'CCLStatus',
          class: 'pa-1',
          active: 1,
        },
        {
          text: 'Chuid',
          value: 'ChuidStatus',
          class: 'pa-1',
          active: 1,
        },
        {
          text: 'FingerprintAuthStatus',
          value: 'FingerprintAuthStatus',
          class: 'pa-1',
          active: 0,
        },
        {
          text: 'PinAuthStatus',
          value: 'PinAuthStatus',
          class: 'pa-1',
          active: 0,
        },
        {
          text: 'Reason',
          value: 'Reason',
          class: 'pa-1',
          active: 0,
        },
        {
          text: 'Screening',
          value: 'ScreeningType',
          class: 'pa-1',
          active: 0,
        },
        {
          text: 'Terminal',
          value: 'Terminal',
          class: 'pa-1',
          active: 0,
        },
        {
          text: 'Access',
          value: 'AccessPoint',
          class: 'pa-1',
          active: 1,
        },
        {
          text: 'IMEI#',
          value: 'IMEINumber',
          class: 'pa-1',
          active: 1,
        },
        {
          text: 'Marsec ',
          value: 'MarsecLevel',
          class: 'pa-1',
          active: 1,
        },
        {
          text: 'Mode ',
          value: 'ModeLevel',
          class: 'pa-1',
          active: 1,
        },
        {
          text: 'Guard',
          value: 'Guard',
          class: 'pa-1',
          active: 1,
        },
        {
          text: 'Direction',
          value: 'Direction',
          class: 'pa-1',
          active: 1,
        },
      ];
      localStorage.setItem('headers', JSON.stringify(state.headers));
    },
  },
  getters: {
    activeFacility: state => state.activeFacility,
    agreeToNotice: state => state.agreeToNotice,
    currentAccessLogRecord: state => state.currentAccessLogRecord,
    // currentFacilityObj: state => state.currentFacilityObj,
    facilityTerminals: state => state.facilityTerminals,
    getAgreeToNotice: state => state.agreeToNotice,
    // getCurrentAdminFacilityObj: state => state.currentAdminFacilityObj,
    getSearchTerm: state => state.searchTerm,
    headers: state => state.headers,
    loading: state => state.loading,
    user: state => state.user,
  },
  actions: { ...actions },
});

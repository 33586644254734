<template>
  <popup-modal ref="popup">
    <v-card>
      <v-card-title class="text-h5" style="text-wrap: nowrap">
        {{ title }}
      </v-card-title>
      <v-card-text>{{ message }} </v-card-text>
      <v-card-actions style="padding-bottom: 10px">
        <v-spacer />
        <v-btn :style="{ color: cancelColor }" text @click="cancel">
          {{ cancelButton }}
        </v-btn>
        <v-btn :style="{ color: confirmColor }" text @click="confirm">
          {{ okButton }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </popup-modal>
</template>

<script>
import PopupModal from './PopupModal';

export default {
  name: 'ConfirmDialogue',
  components: { PopupModal },
  data: () => ({
    // Parameters that change depending on the type of dialogue
    title: undefined,
    message: undefined, // Main text content
    okButton: 'Okay', // Text for confirm button; leave it empty because we don't know what we're using it for
    okButtonColor: undefined,
    cancelButton: 'Go Back', // text for cancel button
    cancelButtonColor: undefined,

    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),
  computed: {
    confirmColor() {
      if (this.okButtonColor) {
        return this.okButtonColor;
      }
      return '#000000';
    },
    cancelColor() {
      if (this.cancelButtonColor) {
        return this.cancelButtonColor;
      }
      return '#000000';
    },
  },
  methods: {
    show(opts = {}) {
      this.title = opts.title;
      this.message = opts.message;
      this.okButton = opts.okButton;
      this.okButtonColor = opts.okButtonColor;
      this.cancelButtonColor = opts.cancelButtonColor;
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    confirm() {
      this.$refs.popup.close();
      this.resolvePromise(true);
    },

    cancel() {
      this.$refs.popup.close();
      this.resolvePromise(false);
      // Or you can throw an error
      // this.rejectPromise(new Error('User canceled the dialogue'))
    },
  },
};
</script>
